<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r" slot-scope="{ data }">
        <el-button
          @click="
            show = true;
            detailData = {};
          "
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
        <el-button
          @click="articleDelete(data.selectRows)"
          icon="el-icon-circle-close"
          class="dele-btn"
          >删除</el-button
        >
      </template>
      <!-- <template slot="right-r" slot-scope="{ data }"
        ><el-button icon="el-icon-upload2" class="export-btn"
          >删除</el-button
        ></template
      > -->
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(data.row)">编辑</el-button>
        <el-button
          @click="articleDelete(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
      <div slot="isEnabled" slot-scope="{ data }">
        <span v-if="data.row.isEnabled" style="color: #00bc0d">是</span>
        <span v-else style="color: #f64a2d">否</span>
      </div>
      <div slot="thumbnail" slot-scope="{ data }">
        <img
          v-if="data.row.thumbnail"
          :src="data.row.thumbnail"
          style="width: 40px; height: 40px"
        />
        <span v-else>---</span>
      </div>
    </SimpleTable>
    <ConsultingAdd
      :show.sync="show"
      :detailData="detailData"
      @submit="$refs.table.getData()"
    ></ConsultingAdd>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import ConsultingAdd from "../components/ConsultingAdd";
import {
  getExpertList,
  deleteExpert,
  exportDataExpert,
  //
  trafficArticleList,
  trafficArticleDelete,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable, ConsultingAdd },
  data() {
    return {
      show: false,
      detailData: {},
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        height: 560,
        searchConfigs: [
          {
            label: "标题",
            type: "input",
            modelKey: "title",
          },
          {
            label: "更新时间",
            type: "daterange",
            daterangeKeys: ["updateDateTimeStart", "updateDateTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "updateT",
          },
          {
            label: "是否显示",
            type: "select",
            modelKey: "isEnabled",
            option: [
              { dictKey: "", dictValue: "全部" },
              { dictKey: 1, dictValue: "是" },
              { dictKey: 0, dictValue: "否" },
            ],
          },
          {
            label: "创建人",
            type: "input",
            modelKey: "createUserName",
          },
          {
            label: "创建时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "title",
            label: "标题",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "thumbnail",
            label: "缩略图",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "sortOrder",
            label: "排序",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "readCount",
            label: "阅读量",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "isEnabled",
            label: "是否显示",
            width: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "createUserName",
            label: "创建人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "创建时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await trafficArticleList(queryParams);
      return {
        localData: res.data.records || [],
        total: res.data.total,
      };
    },
    // 跳转详情或编辑
    toDetail(item) {
      // this.$router.push({
      //   name: "safeDutyExpertManagementDetail",
      //   params: {
      //     id: item?.id,
      //     isEdit: edit,
      //   },
      // });
      console.log(item);
      this.detailData = item;
      this.show = true;
    },
    articleDelete(items) {
      let dids = [];
      if (items instanceof Array) {
        dids = items.map((item) => item.id);
      } else {
        dids.push(items.id);
      }
      this.$confirm("删除后对应数据也将清理，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        trafficArticleDelete(dids).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    exportData(data = {}) {
      console.log(111111);
      exportDataExpert(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
