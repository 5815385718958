var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.detailData && _vm.detailData.id ? '编辑' : '添加',"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":"","width":"40%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"title"}},[_c('TextInput',{attrs:{"config":{
            type: 'input',
            required: true,
            label: '标题：',
            width: '100%',
          }},model:{value:(_vm.dataForm.title),callback:function ($$v) {_vm.$set(_vm.dataForm, "title", $$v)},expression:"dataForm.title"}})],1),_c('el-form-item',{attrs:{"prop":"thumbnail"}},[_c('TextInput',{attrs:{"config":{
            type: 'slot',
            required: true,
            label: '缩略图：',
            width: '100%',
          }}},[_c('div',[_c('div',{on:{"click":_vm.uploadFun}},[(_vm.dataForm.thumbnail)?_c('img',{staticClass:"upload",attrs:{"src":_vm.dataForm.thumbnail}}):_c('div',{staticClass:"upload",staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('i',{staticClass:"el-icon-plus"})])]),_c('span',{staticStyle:{"font-weight":"400","font-size":"14px","color":"#999999"}},[_vm._v("建议宽高比16:9 格式:jpg/jpeg/png ")])])])],1),_c('el-form-item',{attrs:{"prop":"isEnabled"}},[_c('TextInput',{attrs:{"config":{
            type: 'radio',
            label: '是否显示：',
            width: '100%',
            option: [
              {
                label: '启用',
                value: 1,
              },
              {
                label: '禁用',
                value: 0,
              },
            ],
          }},model:{value:(_vm.dataForm.isEnabled),callback:function ($$v) {_vm.$set(_vm.dataForm, "isEnabled", $$v)},expression:"dataForm.isEnabled"}})],1),_c('el-form-item',{attrs:{"prop":"readCount"}},[_c('TextInput',{attrs:{"config":{
            type: 'inputNumber',
            label: '阅读数量：',
            width: '100%',
          }},model:{value:(_vm.dataForm.readCount),callback:function ($$v) {_vm.$set(_vm.dataForm, "readCount", $$v)},expression:"dataForm.readCount"}})],1),_c('el-form-item',{attrs:{"prop":"sortOrder"}},[_c('TextInput',{attrs:{"config":{
            type: 'inputNumber',
            label: '排序：',
            width: '100%',
          }},model:{value:(_vm.dataForm.sortOrder),callback:function ($$v) {_vm.$set(_vm.dataForm, "sortOrder", $$v)},expression:"dataForm.sortOrder"}})],1),_c('el-form-item',{attrs:{"prop":"content"}},[_c('TextInput',{attrs:{"config":{
            type: 'slot',
            label: '内容：',
            width: '100%',
          }}},[_c('quill-editor',{ref:"QuillEditor",attrs:{"options":_vm.editorOption,"height":400},model:{value:(_vm.dataForm.content),callback:function ($$v) {_vm.$set(_vm.dataForm, "content", $$v)},expression:"dataForm.content"}})],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }